import { Siddi } from "@creately/siddi";
/**
 * This interface defines the event property required by the tracker
 * NOTE: only this structure is allowed to be stored on Snowplow
 * Database as of now. Need to expand these if more fields are required
 */
export interface ITrackingEventProperty {
  value1Type?: string;
  value1?: string;
  value2Type?: string;
  value2?: string;
  value3Type?: string;
  value3?: string;
}

/**
 * Class Tracker
 * This is a wrapper class for Siddi event tracker.
 */
export class Tracker {
  /**
   * Current Siddi instance
   */
  protected static siddi: any;

  /**
   * Time when the current app session started.
   */
  protected static sessionStart: number;

  /**
   * This holds all global properties required by trackers
   */
  protected static _globals: any = {};

  /**
   * Holds the event tracker configuration.
   */
  protected static EVENT_TRACKER_CONFIG = [
    { name: "indicative" },
    { name: "matomo" },
    { name: "snowplow" },
    { name: "ga4" },
  ];

  /**
   * Siddi track function wrapper. When passing the event name, application name must be ommiad.
   * The function will add the relevant application namespace by default.
   * WARNING: eventProperties passed into this function will get modified
   * do.click not proton.do.click
   * @param eventName Event to track
   * @param eventProperties Event properties if required
   */
  public static track(
    eventName: string,
    eventProperties?: ITrackingEventProperty
  ) {
    try {
      if (!this.siddi) {
        this.siddi = new Siddi(this.EVENT_TRACKER_CONFIG);
      }
      const props = eventProperties ? eventProperties : {};
      const appEventName = `${process.env.APP_NAMESPACE}.${eventName}`;
      const partialEventName = eventName.split(".");
      const mergedProperties = Object.assign(props, {
        url: window.location.pathname,
        location: partialEventName[0],
        subLocation: partialEventName[1],
        ...this.globals,
      });
      this.siddi.track(appEventName, mergedProperties);
    } catch (error) {
      // Siddi tracking is failing due to some reason
      // Ignoring the errors for the moment
    }
  }

  /**
   * Identify the user to associate tracking events. Ideally, this need to called
   * whenever the application is loaded or user is authenticated.
   * @param userId Creately user id
   * @param userProperties Additional user properties if required
   */
  public static setUser(userId: string, userProperties?: Object) {
    try {
      if (!this.siddi) {
        this.siddi = new Siddi(this.EVENT_TRACKER_CONFIG);
      }
      this.siddi.identify(userId, userProperties);
      this.setGlobalProperty("userId", userId);
    } catch (error) {
      // Siddi tracking is failing due to some reason
      // Ignoring the errors for the moment.
    }
  }

  /**
   * This function sets user id using gravity if user id is not available
   */
  protected static setUserIdFromGravity() {
    if (
      (window as any).gravity &&
      (window as any).gravity.client.hasValidSession()
    ) {
      this.setUser((window as any).gravity.client.getCurrentUserID());
    }
  }

  /**
   * This function sets user plan using gravity if user plan is not available
   */
  protected static setUserPlanFromGravity() {
    if (
      (window as any).gravity &&
      (window as any).gravity.client.hasValidSession()
    ) {
      const planId = (window as any).gravity.client.getCurrentUserPlan();
      let planType = planId.split("-")[0].toLocaleUpperCase();
      if (planType === "PUBLIC") {
        planType = "FREE";
      }
      this.setGlobalProperty("plan", planType);
    }
  }

  /**
   * Track the loading of the application
   */
  public static async trackLoad() {
    await this.setDomainUserIdFromSnowplow();
    this.setUserIdFromGravity();
    this.setUserPlanFromGravity();
    this.sessionStart = this.getTimeInMs();
    this.track("account.load");
  }

  /**
   * Track the unloading of the application
   */
  public static trackExit() {
    const duration = this.getCurrentSessionTime();
    const sessionDuration = duration ? duration : 0;
    this.track("account.exit", {
      value1Type: "duration",
      value1: `${sessionDuration}`,
    });
  }

  /**
   * This function adds the domain userId to all events from snowplow
   */
  protected static async setDomainUserIdFromSnowplow() {
    return new Promise((resolve) => {
      if ((window as any).snowplow) {
        (window as any).snowplow(function () {
          // @ts-ignore: Ignore implicit this
          const cf = this.cf;
          const domainUserId = cf.getDomainUserId();
          Tracker.setGlobalProperty("domainUserId", domainUserId);
          resolve(true);
        });
        return;
      }
      resolve(true);
    });
  }

  /**
   * Returns the current time in mili seconds
   */
  protected static getTimeInMs(): number {
    return new Date().getTime();
  }

  public static getCurrentSessionTime() {
    return Math.round((this.getTimeInMs() - this.sessionStart) / 1000);
  }

  /**
   * This function sets properties to globals
   * @param propertyName Name of the property
   * @param value
   */
  public static setGlobalProperty(propertyName: string, value: any) {
    this.globals[propertyName] = value;
  }

  /**
   * This returns all the globals
   */
  public static get globals() {
    return this._globals;
  }
}
